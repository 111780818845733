<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FACULTY</h2>
        </div>
    </div>
    <div class="shape9"><img src="https://odemy-ng.envytheme.com/assets/img/shape8.svg" alt="image"></div>
</div>

<div class="advisor-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let item of instructors">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img [src]="item.imageSrc" alt="image">
                        <ul class="social-link">
                            <li><a [href]="item.linkedin" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a [href]="item.linkedin" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">{{item.name}}</a></h3>
                        <span>{{item.designation}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Apply As Instructor</h2>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>Sarveksha Academy, 49-24-25/A/4, 2nd Floor, Suri Plaza, Sankara Matam Rd, opp. Madhura Inn Hotel, Visakhapatnam, Andhra Pradesh 530016</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+8886318755">(888-631-8755 / 888-632-7755)</a></p>
                            <p>Mail: <a href="mailto:hello@odemy.com">info.sarveksha@gmail.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 AM - 05:00 PM</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Send Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="premium-access-area pb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="https://odemy-ng.envytheme.com/assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="https://odemy-ng.envytheme.com/assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="https://odemy-ng.envytheme.com/assets/img/shape7.png" alt="image"></div>
</div>