<div class="funfacts-and-feedback-area ptb-100" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Flexible Study at Your Own Pace, According to Your Own Needs</h2>
                    <p>We offer online training in a wide variety of its courses through Skype as well, while also having our own online education portal. Students located anywhere in the world and get themselves trained and learned in the IT courses in their free time.</p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Students are no longer required to be physically present and any of our institute branches and can enjoy the benefits and comfort of self-paced learning. Online training is provided by IT experts, and students also get experience in real-world IT projects. We actually have enrolled with us students from different countries (including those from North America, Europe, and other parts of the world) attending live courses and seminars online at a very affordable cost.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="https://odemy-ng.envytheme.com/assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Through our experience, we learn that many students, especially those who are working in an IT or other professions, want to go through the courses in lesser time because of professional or other reasons. We, therefore, have created personalized and customized IT courses that provide for Fast-Track learning. Students can pick the subject areas and topics they want to learn first and may go through the rest of the course later.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="https://odemy-ng.envytheme.com/assets/img/user2.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>Sarah Taylor</h3>
                                            <span>PHP Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Through our experience, we learn that many students, especially those who are working in an IT or other professions, want to go through the courses in lesser time because of professional or other reasons. We, therefore, have created personalized and customized IT courses that provide for Fast-Track learning. Students can pick the subject areas and topics they want to learn first and may go through the rest of the course later.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="https://odemy-ng.envytheme.com/assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>David Warner</h3>
                                            <span>QA Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="feedback-info">
                        <p>Not a member yet?​ <a routerLink="/profile-authentication">Register now</a></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="1926">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="3279">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="250">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="https://odemy-ng.envytheme.com/assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="https://odemy-ng.envytheme.com/assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="https://odemy-ng.envytheme.com/assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="https://odemy-ng.envytheme.com/assets/img/shape8.svg" alt="image"></div>
</div>