<div class="courses-area pb-70" style="padding-top: 50px;">
    <div class="container">
        <div class="section-title">
            <span class="sub-title" style="font-size: larger;">POPULAR courses</span>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let item of courses" [routerLink]="'/single-courses-1/'+item.id">
                <div class="single-courses-box">
                    <div *ngIf="item.discount>0" class="ribbon up">
                        <div class="content"><mat-icon>access_time_filled</mat-icon></div>
                    </div>
                    <p *ngIf="item.discount>0" class="ribbon2">
                        <span class="text"><strong class="bold">Special Offer:</strong> {{item.discount+"% off Limited Time"}}  </span>
                    </p>
                    <div class="courses-image">
                        <a [routerLink]="'/single-courses-1/'+item.id" class="d-block image">
                            <img [src]="item.image" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">{{item.price+" ₹"}}</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>{{item.instructor}}</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">{{item.name}}</a></h3>
                        <p>{{item.description.substr(0,100)+"...."}}</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> {{item.lessons+"  Lessons"}}</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape16"><img src="assets/img/shape15.png" alt="image"></div>
</div>