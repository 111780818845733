<div class="container">
    <div class="premium-access-content">
        <span class="sub-title">Sponsor partners</span>
        <div class="row partners">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-item">
                    <img src="assets/img/partner/logo1.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-item">
                    <img src="assets/img/partner/logo2.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-item">
                    <img src="assets/img/partner/logo3.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-item">
                    <img src="assets/img/partner/logo4.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-item">
                    <img src="assets/img/partner/logo5.jpeg" alt="image">
                </div>
            </div>
        </div>
        
    </div>
</div>