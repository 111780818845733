<div class="boxes-area boxes-style-two bg-f5f7fa pb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div *ngFor="let item of cards" class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img [src]="item.image" alt="image">
                    </div>
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                    <a routerLink="/courses-1" class="default-btn" style="padding-left: 35px;">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</div>
