<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<app-modern-schooling-about></app-modern-schooling-about> 

<app-instructor></app-instructor>

<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>