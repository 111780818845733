<app-modern-schooling-banner></app-modern-schooling-banner>

<app-boxes></app-boxes>

<!-- <app-modern-schooling-about></app-modern-schooling-about> -->

<app-overview></app-overview>

<app-modern-schooling-courses></app-modern-schooling-courses>

<!-- <app-feedback></app-feedback> -->

<!-- <app-top-categories></app-top-categories> -->

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<!-- <app-modern-schooling-events></app-modern-schooling-events> -->

<div class="subscribe-area ">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="https://odemy-ng.envytheme.com/assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="https://odemy-ng.envytheme.com/assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="https://odemy-ng.envytheme.com/assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="https://odemy-ng.envytheme.com/assets/img/shape14.png" alt="image"></div>
</div>

