<div class="about-area-two bg-fffaf3 pt-70 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/contact" class="link-btn">Explore Learning</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="assets/img/about-img5.jpg" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn" target="_blank"><i class="flaticon-play"></i></a>
                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
</div>