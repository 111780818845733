<div class="categories-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Categories</span>
            <h2>Top Categories</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img1.jpg" alt="image">
                    <div class="content">
                        <h3>Development</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img2.jpg" alt="image">
                    <div class="content">
                        <h3>Business</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img3.jpg" alt="image">
                    <div class="content">
                        <h3>IT & Software</h3>
                        <span>15 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img4.jpg" alt="image">
                    <div class="content">
                        <h3>Design</h3>
                        <span>11 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img5.jpg" alt="image">
                    <div class="content">
                        <h3>Lifestyle</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img6.jpg" alt="image">
                    <div class="content">
                        <h3>Photo & Flim</h3>
                        <span>12 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img7.jpg" alt="image">
                    <div class="content">
                        <h3>Animation</h3>
                        <span>05 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="https://odemy-ng.envytheme.com/assets/img/categories/img8.jpg" alt="image">
                    <div class="content">
                        <h3>Writing</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="categories-btn-box">
                    <a routerLink="/categories" class="default-btn"><i class="flaticon-user"></i>View All Categories<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>
