// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyCg9Mxi1x4oeHwHrrA5BeuQEm-js4EmMwI",
  authDomain: "sarveksha-2be3c.firebaseapp.com",
  projectId: "sarveksha-2be3c",
  storageBucket: "sarveksha-2be3c.appspot.com",
  messagingSenderId: "987837370477",
  appId: "1:987837370477:web:6509f1d987158d10ce2f64",
  measurementId: "G-96Y99EFH22"
};