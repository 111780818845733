<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Dashboard</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="checkout-area" style="margin-top: 30px; margin-bottom: 100px;">
    <div class="" style="padding: 10px;">
        <!-- <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Returning customer? <a routerLink="/profile-authentication">Click here to login</a></span>
        </div> -->
        <div class="row">
            <div class="col-lg-9 col-md-12">
                <div class="billing-details">
                    <h3 class="title">Task Details</h3>
                    <div class="tasks">
                        <div class="task" *ngFor="let task of tasks" (click)="openTask(task.id)">
                            <img [src]="task.image" alt="" srcset="">
                            <div class="" style="width: 100%;">
                                <div class="details">
                                    <div class="info">
                                        <p class="name">{{task.title}}</p>
                                        <p class="earn">{{"Earn "+task.amount+" ₹"}}</p>
                                    </div>
                                    <div class="category">{{task.taskType}}</div>
                                    <p class="desc">{{task.tasksubType}}</p>
                                </div>
                                <div class="buttons">
                                    <div class="watch"><a target="blank" [href]="task.youtubeLink">Watch Video</a></div>
                                    <div class="btn">Open Task<span></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12" style="padding: 10px;">
                <div class="order-details billing-details">
                    <h3 class="title">Upload Your Task</h3>
                    <div class="row uploadtask">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Task <span class="required">*</span></label>
                                <div class="select-box">
                                    <select id="taskName">
                                        <option *ngFor="let type of tasksList">{{type}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Name <span class="required">*</span></label>
                                <input id="userName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <label>Phone Number <span class="required">*</span></label>
                                <input id="phoneNumber" type="number" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <label>Pan / Aadhar <span class="required">*</span></label>
                                <input id="panNumber" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <button (click)="uploadTask()" class="default-btn" style="padding: 10px 15px;">Upload your task<span></span></button>
                        </div>
                    </div>
                    <!-- <div class="payment-box">
                        <div class="payment-method">
                            <p>
                                <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
                            </p>
                            <p>
                                <input type="radio" id="paypal" name="radio-group">
                                <label for="paypal">PayPal</label>
                            </p>
                            <p>
                                <input type="radio" id="cash-on-delivery" name="radio-group">
                                <label for="cash-on-delivery">Cash on Delivery</label>
                            </p>
                        </div>
                        <button type="submit" class="default-btn"><i class="flaticon-shopping-cart"></i>Place Order<span></span></button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 100px;">
        <h3 class="title">Transactions</h3>
        <div class="order-table table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">DATE</th>
                        <th scope="col">PERSON NAME</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">TASk NAME</th>
                        <th scope="col">MOBILE NUMBER</th>
                        <th scope="col">PAN NUMBER</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">PAYMENT STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of taskTransactions">
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.date}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.userName}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.amount}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.taskName}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.phone}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.pan}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.status}}</span>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">{{item.paymentStatus}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="container" style="margin-top: 50px;">
        <h3 class="title">Terms and Conditions</h3>
        <ul class="terms">
            <li>1. All the registered users should update their KYC with Aadhar & Pan Card.</li>
            <li>2. All the users should fill task update form after completing their tasks.</li>
            <li>3. Minimum withdrawal amount from wallet is Rs 100 /-.</li>
            <li>4. Sarveksha is not liable if the user provides wrong bank account details.</li>
            <li>5. It will take 24 hours of time to credit the user bank account after withdrawal from their wallet.</li>
        </ul>
    </div>
    
</div>