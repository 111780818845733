<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="https://www.sarveksha.com/assets/images/logosarveksha.png" alt="logo"></a>
                    <p>Sarveksha Academy is a leader in providing technical and management training for Students.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-1">About</a></li>
                        <li><a routerLink="/courses-1">Courses</a></li>
                        <li><a routerLink="/events">Events</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Student Success</a></li>
                        <li><a routerLink="/">Scholarships</a></li>
                        <li><a routerLink="/">For Business</a></li>
                        <li><a routerLink="/">Go Premium</a></li>
                        <li><a routerLink="/">Team Plans</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Visakhapatnam & Hyderabad</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:8886317755"> +91 8886317755</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:Info.buddica@gmail.com">Info.buddica@gmail.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+91 8886317755</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
