<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Success Story</h2>
        </div>
    </div>
    <div class="shape9"><img src="https://odemy-ng.envytheme.com/assets/img/shape8.svg" alt="image"></div>
</div> -->

<!-- <div class="success-story-area">
    <div class="container">
        <div class="video-box mt-0">
            <div class="image">
                <img src="https://odemy-ng.envytheme.com/assets/img/success-story.jpg" class="shadow" alt="image">
            </div>
            <a [href]="placementData.videoLink" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
            <div class="shape10"><img src="https://odemy-ng.envytheme.com/assets/img/shape9.png" alt="image"></div>
        </div>
    </div>
    <div class="shape2"><img src="https://odemy-ng.envytheme.com/assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="https://odemy-ng.envytheme.com/assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="https://odemy-ng.envytheme.com/assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="https://odemy-ng.envytheme.com/assets/img/shape8.svg" alt="image"></div>
</div> -->

<!-- <app-our-story></app-our-story> -->

<app-success-people></app-success-people>