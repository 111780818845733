<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/Sarveksha Colour Logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <form class="search-box">
                    <input type="text" (focus)="focus()" (blur)="blur()" class="input-search" placeholder="Search for anything">
                    <div id="drop" style="position: absolute; z-index: 100; background-color: white; display: none;">
                        <ul class="">
                            <li class="nav-item" *ngFor="let item of courses"><a
                                class="nav-link" (click)="check('/single-courses-1/'+item.id)" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{item.name}}</a></li>
                        </ul>
                    </div>
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form>
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home</a></li>
                    <li *ngIf="user" class="nav-item"><a routerLink="/tasks" class="nav-link">Tasks</a></li>
                    <li class="nav-item"><a routerLink="/about-1" class="nav-link">About Us</a></li>
                    <li class="nav-item megamenu"><a routerLink="/courses-1" class="nav-link">Courses</a></li>
                    <li class="nav-item"><a routerLink="/teacher" class="nav-link">Faculty</a></li>
                    <!-- <li class="nav-item"><a routerLink="/success-story" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Placements</a></li> -->
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                            <li class="nav-item"><a routerLink="/blog-4" class="nav-link">Blog</a></li>
                            <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                        </ul>
                    </li> -->
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" (click)="toggleClass()" class="nav-link">Home</a></li>
                    <li *ngIf="user" class="nav-item"><a routerLink="/tasks" class="nav-link">Tasks</a></li>
                    <li class="nav-item"><a routerLink="/about-1" (click)="toggleClass()"  class="nav-link">About Us</a></li>
                    <li class="nav-item megamenu"><a routerLink="/courses-1" (click)="toggleClass()"  class="nav-link">Courses</a></li>
                    <li class="nav-item"><a routerLink="/teacher" (click)="toggleClass()"  class="nav-link">Faculty</a></li>
                    <!-- <li class="nav-item"><a routerLink="/success-story" (click)="toggleClass()"  class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Placements</a></li> -->
                    <li class="nav-item"><a routerLink="/contact" (click)="toggleClass()" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                            <li class="nav-item"><a routerLink="/blog-4" class="nav-link">Blog</a></li>
                            <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                        </ul>
                    </li> -->
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <!-- <div class="option-item">
                    <div class="cart-btn">
                        <a routerLink="/cart"><i class='flaticon-shopping-cart'></i><span>3</span></a>
                    </div>
                </div> -->
                <div class="option-item">
                    <a *ngIf="!user" (click)="login()" class="default-btn"><i class="flaticon-user"></i>Login/Register<span></span></a>
                    <a *ngIf="user" routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>{{user.username}}<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>
