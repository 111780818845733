<div class="about-area bg-f8f9f8 " style="margin-top: -15px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="https://odemy-ng.envytheme.com/assets/img/banner-img2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Develop Your Skills, Learn Something New</h2>
                    <p>We at Sarveksha Academy are a group of IT professionals, techies, faculty, and industry experts who have been providing top quality IT education in a comprehensive range of fields and areas, to students in India and abroad. Our courses are updated timely and are in the most in-demand/latest realms of IT. Our academic/practical courses and their content is created through the help of industry experts as well, so the students attain the most advanced skill set, knowledge and are able to solve the real-world problems businesses face. We expose our students to real-world projects and have word class and futuristic IT technologies, digital labs, and expert education.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Skiled Teachers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Afordable Courses</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Lifetime Access</span></li>
                    </ul>
                    <a (click)="login()" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area bg-f8f9f8 " style="margin-top: 0; padding-top: 100px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>Vision and Misson</h2>
                    <span class="sub-title">{{about.title}}</span>
                    <p>{{about.content}}</p>
                </div>
                <div class="about-content">
                    <span class="sub-title">{{about.title2}}</span>
                    <p>{{about.content2}}</p>
                    <a (click)="login()" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img style="object-fit: cover; width: 500px; height: 500px;" [src]="about.image" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
